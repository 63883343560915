import axiosInstance from "api/axiosInstance";

/**
 *  GET /patient/user
 *
 *  get all patients
 */
export const getPatients = async (token) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};

	return await axiosInstance
		.get("/patient/user", { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  POST /patient/user
 *
 *  create a patient
 */
export const createPatient = async ({
	token,
	firstName,
	lastName,
	gender,
	birthDate,
	bodyWeight,
	bodyUnitId,
	kidneyFunction,
	patientHistory,
}) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	let data = {
		first_name: firstName,
		last_name: lastName,
		gender: gender,
		birth_date: birthDate,
		body_weight: bodyWeight,
		body_unit_id: bodyUnitId,
		kidney_function: kidneyFunction,
		patient_history: patientHistory,
	};

	return await axiosInstance
		.post("/patient/user", data, { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  GET /patient/user/{patient_id}
 *
 *  get a patient
 */
export const getPatient = async (token, patientId) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};

	return await axiosInstance
		.get(`/patient/user/${patientId}`, { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  PUT /patient/user/{patient_id}
 *
 *  update a patient
 */
export const updatePatient = async ({
	token,
	id,
	firstName,
	lastName,
	gender,
	birthDate,
	bodyWeight,
	bodyUnitId,
	kidneyFunction,
	patientHistory,
}) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	let data = {
		first_name: firstName,
		last_name: lastName,
		gender: gender,
		birth_date: birthDate,
		body_weight: bodyWeight,
		body_unit_id: bodyUnitId,
		kidney_function: kidneyFunction,
		patient_history: patientHistory,
	};

	return await axiosInstance
		.put(`/patient/user/${id}`, data, { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  DELETE /patient/user/{patient_id}
 *
 *  delete a patient
 */
export const deletePatient = async (token, patientId) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	return await axiosInstance
		.delete(`/patient/user/${patientId}`, { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};
