import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import Modal from "components/Modal/Modal";
import Badge from "components/Badge/Badge";
import Button from "components/Form/Button/Button";
import PatientsTable from "components/PatientsTable/PatientsTable";
import { getDate } from "utils/utils";

import { getPatients, getPatient } from "api/patients";

import { useAuth } from "context/AuthContext";

import "./SelectPatientModal.scss";

const SelectPatientModal = (props) => {
	const [patient, setPatient] = useState(null);
	const { t } = useTranslation();
	const historyBadges = [];

	if (patient?.patient_history)
		patient["patient_history"].forEach((item, idx) => {
			if (item.description)
				historyBadges.push(
					<Badge key={idx} className="bg-slate-400" value={item.description} />,
				);
		});
	if (historyBadges.length === 0) historyBadges.push("-");

	// hooks
	const { authData } = useAuth();

	// table - states
	const [tableData, setTableData] = useState([]);
	const [refreshTable, setRefreshTable] = useState(false);

	const handleRowClick = async (row) => {
		setPatient(row.original);

		const data = await getPatient(authData.access_token, row.original.id);

		if (data === "ERR_BAD_REQUEST") {
			toast.error(t(data.response.response.data));
			console.log(data);
		} else {
			setPatient(data);
		}
	};

	const handleSubmit = () => {
		console.log(patient);
		props.onSubmit(patient);
		setPatient("");
		props.onClose();
	};

	useEffect(() => {
		(async () => {
			const tableData = await getPatients(authData.access_token);

			if (tableData === "ERR_BAD_REQUEST") {
				// alert("Cannot get sessions: ERR_BAD_REQUEST");
				// setShowSessionExpiredModal(true);
			} else {
				setTableData(tableData);
				setRefreshTable(false);
			}
		})();
	}, [authData.access_token, refreshTable]);

	return (
		<Modal
			title={t("Select patient")}
			isOpen={props.isOpen}
			onClose={props.onClose}
			onSubmit={props.onSubmit}
			className="select-patient-modal w-3/5 h-80"
		>
			<div className="flex h-full w-full">
				<div className="flex w-75 flex-col row-gap-1">
					<div className="flex gap-2 h-full">
						<div className="w-full flex flex-col">
							<h3 className="mt-1">{t("Patient list")}</h3>
							<PatientsTable
								data={tableData}
								searchLabel={t("Search by first name and/or last name")}
								initialState={{ hiddenColumns: ["accessor", "is_active"] }}
								onRowClick={handleRowClick}
							/>
						</div>
					</div>
				</div>
				<div className="aside p-1 ml-3 flex flex-col">
					<h3 className="mb-1">{t("Patient information")}</h3>
					<div className="table font-xs">
						<div className="table-row">
							<strong>{t("Patient Id")}</strong>
							<span>{patient?.patient?.id}</span>
						</div>
						<div className="table-row">
							<strong>{t("First name")}</strong>
							<span>{patient?.patient?.first_name}</span>
						</div>
						<div className="table-row">
							<strong>{t("Last name")}</strong>
							<span>{patient?.patient?.last_name}</span>
						</div>
						<div className="table-row">
							<strong>{t("Date of birth")}</strong>
							<span>
								{patient?.patient?.birth_date &&
									getDate(patient.patient.birth_date)}
							</span>
						</div>
						<div className="table-row">
							<strong>{t("Age")}</strong>
							<span>{patient?.patient?.age}</span>
						</div>
						<div className="table-row">
							<strong>{t("Sex")}</strong>
							<span>{patient?.patient?.gender}</span>
						</div>
						<div className="table-row">
							<strong>{t("Body weight")}</strong>
							<span>
								{`${patient.patient.body_weight || "-"} ${t(
									patient.patient.body_unit,
								)}`}
							</span>
						</div>
						<div className="table-row">
							<strong>{t("eGFR")}</strong>
							<span>
								{patient.patient.kidney_function || "-"}{" "}
								{patient.patient.kidney_function && t("ml/min/1.73m²")}
							</span>
						</div>
						<div className="table-row">
							<strong>{t("Medical history")}</strong>
						</div>
					</div>
					<div className="table font-xs">
						<div className="table-row">
							<p>{historyBadges}</p>
						</div>
					</div>
					<div className="flex flex-col justify-center mt-auto">
						<Button
							className={`btn-primary mt-3 ${!patient && "disabled"}`}
							onClick={handleSubmit}
						>
							{t("Select patient")}
						</Button>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default SelectPatientModal;
