import axiosInstance from "api/axiosInstance";

/**
 *  GET /unitType
 *
 *  get all unit types
 */
export const getUnitTypes = async (token) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};

	return await axiosInstance
		.get("/unitType", { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  GET /unitType/{unit_type_id}/unit
 *
 *  get all units from a unit type
 */
export const getUnits = async ({ token, unitTypeId }) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};

	return await axiosInstance
		.get(`/unitType/${unitTypeId}/unit`, { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};
