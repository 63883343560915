import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import PatientModal from "components/PatientModal/PatientModal";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import PatientsTable from "components/PatientsTable/PatientsTable";

import {
  createPatient,
  getPatients,
  updatePatient,
  deletePatient,
} from "api/patients";

import { useAuth } from "context/AuthContext";
import { useUser } from "context/UserContext";

export default function Patients() {
  // modals - states
  const [showPatientModal, setShowPatientModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // hooks
  const { authData } = useAuth();
  const { canI } = useUser();
  const { t /*, i18n*/ } = useTranslation();

  // table - states
  const [patients, setPatients] = useState([]);
  const [refreshTable, setRefreshTable] = useState(false);
  const [patient, setPatient] = useState(null);

  const createNewPatient = async (patientData) => {
    const response = await createPatient({
      token: authData.access_token,
      firstName: patientData.first_name,
      lastName: patientData.last_name,
      birthDate: patientData.birth_date,
      gender: patientData.gender,
      bodyWeight: patientData.body_weight,
      bodyUnitId: patientData.body_unit_id,
      kidneyFunction: patientData.kidney_function,
      patientHistory: [],
    });
    if (response.code === "ERR_BAD_REQUEST") {
      toast.error(t(response.response.data.detail));
    } else {
      toast.success(t("Patient created!"));
      setRefreshTable(true);
    }
  };

  const updatePatient2 = async (patientData) => {
    const response = await updatePatient({
      token: authData.access_token,
      id: patientData.id,
      firstName: patientData.first_name,
      lastName: patientData.last_name,
      birthDate: patientData.birth_date,
      gender: patientData.gender,
      bodyWeight: patientData?.body_weight,
      bodyUnitId: patientData?.body_unit_id,
      kidneyFunction: patientData?.kidney_function,
      patientHistory: patientData?.patient_history,
    });
    if (response.code === "ERR_BAD_REQUEST") {
      toast.error(t(response.response.data.detail));
    } else {
      toast.success(t("Patient data updated!"));
      setRefreshTable(true);
    }
  };

  // table - actions

  const rowActions = [];

  if (canI("update", "Patient"))
    rowActions.push({
      name: "edit",
      icon: "ri-pencil-line",
      command: handleEdit,
      title: t("Edit patient file"),
    });

  if (canI("delete", "Patient"))
    rowActions.push({
      name: "lock",
      icon: "ri-lock-line",
      command: handleDelete,
      title: t("Lock patient file"),
    });

  // table - action handlers

  function handleEdit(row) {
    setPatient(row);
    setShowPatientModal(true);
  }

  function handleDelete(row) {
    setPatient(row);
    setShowDeleteModal(true);
  }

  const deleteConfirmed = async () => {
    setShowDeleteModal(false);
    const response = await deletePatient(authData.access_token, patient.id);
    console.log(response);
    setPatient(null);
    setRefreshTable(true);
  };

  const applyFilters = (data) => {
    // only return active elements
    return data.filter((object) => object.is_active);
  };

  /* get the patients */

  useEffect(() => {
    (async () => {
      const patientsData = await getPatients(authData.access_token);

      if (patientsData.code === "ERR_BAD_REQUEST") {
        toast.error(t(patientsData.response.data.detail));
      } else {
        setPatients(applyFilters(patientsData));
        setRefreshTable(false);
      }
    })();
  }, [authData.access_token, refreshTable, t]);

  return (
    <>
      <div className="view">
        <div className="header">
          <h1>{t("Patients")}</h1>
          <div className="flex mb-2">
            <div className="description max-w-4xl">
              <p className="message__note">
                <i className="ri-book-open-line"></i>
                {t(
                  "In this view teachers and administrators can manage patients. The students do not have access to this page.",
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="content">
          <h2>{t("List of patients")}</h2>
          <PatientsTable
            data={patients}
            actions={rowActions}
            searchLabel={t("Search by first name and/or last name")}
            newAction={() => {
              setPatient(null);
              setShowPatientModal(true);
            }}
            newActionLabel={t("Create new patient")}
          />
        </div>
      </div>

      {showPatientModal && (
        <PatientModal
          patientData={patient}
          onClose={() => setShowPatientModal(false)}
          onSubmit={(patientData) => {
            if (patient) {
              updatePatient2(patientData);
            } else {
              createNewPatient(patientData);
            }
          }}
        />
      )}

      {showDeleteModal && (
        <ConfirmationModal
          title={t("Lock patient?")}
          message=<span>
            {t("Are you sure you want to lock patient _patient_full_name_?", {
              fullName: `${patient.first_name} ${patient.last_name}`,
            })}
          </span>
          yesMessage={t("Lock")}
          onClose={() => {
            setPatient(null);
            setShowDeleteModal(false);
          }}
          onSubmit={deleteConfirmed}
        />
      )}
    </>
  );
}

Patients.displayName = "Patients";
