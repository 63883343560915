import React, { useState } from "react";
import { parse, isValid } from "date-fns";

import "./DateInput.scss";

const DateInput = ({
  id,
  className = "",
  label,
  value,
  placeholder = "DD-MM-YYYY",
  invalidMessage = "The entered date is not valid.",
  onChange,
  ...rest
}) => {
  const [isValidDate, setIsValidDate] = useState(true);
  let classes = ["date-input-container"];

  if (className) classes.push(className);

  const validateDateFormat = (event) => {
    if (event.target.value === "") return;
    setIsValidDate(
      isValid(parse(event.target.value, "dd-MM-yyyy", new Date())),
    );
  };

  return (
    <div className={[...classes, isValidDate ? "" : "invalid-date"].join(" ")}>
      <div className="outline-wrapper">
        <label htmlFor={id} className={value && "filled"}>
          {label}
          {rest.required && !rest.readOnly ? " *" : ""}
        </label>
        <input
          id={id}
          className={isValidDate ? "" : "invalid-date"}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={validateDateFormat}
          onFocus={(e) => {
            setIsValidDate(true);
          }}
          {...rest}
        />
      </div>
      <div className="invalid-message">{invalidMessage}</div>
    </div>
  );
};

export default DateInput;
