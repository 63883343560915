import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { parse, format, isValid } from "date-fns";

import { useAuth } from "context/AuthContext";

import { getUnits } from "api/unitTypes";
import { getPatientHistory } from "api/patientHistory";

import Modal from "components/Modal/Modal";
import Button from "components/Form/Button/Button";
import Input from "components/Form/Input/Input";
import InputNumber from "components/Form/Input/InputNumber";
import Select from "components/Form/Select/Select";

import "./PatientModal.scss";

// change the format of a date string
const formatDate = (date, patternIn, patternOut) => {
	if (!(date && patternIn && patternOut)) return;
	const dateObj = parse(date, patternIn, new Date());
	if (!isValid(dateObj)) return;
	return format(dateObj, patternOut);
};

const PatientModal = ({ patientData, onSubmit, ...props }) => {
	const { authData } = useAuth();
	const { t } = useTranslation();
	const [patient, setPatient] = useState(
		patientData || {
			first_name: "",
			last_name: "",
			birth_date: "",
			gender: "",
			body_weight: "",
			body_unit_id: "",
			body_unit: "",
			kidney_function: "",
		},
	);
	const [weightUnits, setWeightUnits] = useState([]);
	const [history, setHistory] = useState("");
	const [birthDate, setBirthDate] = useState(
		patientData
			? formatDate(patient?.birth_date, "yyyy-MM-dd", "dd-MM-yyyy")
			: "",
	);
	const selectSexOptions = [
		{ value: "Female", label: t("Female") },
		{ value: "Male", label: t("Male") },
	];
	const [sexClass, setSexClass] = useState("");
	const [bodyUnitClass, setBodyUnitClass] = useState("");

	const formatHistoryData = (data) => {
		const formatted = [];

		// here is the bug!!

		if (data)
			data.forEach((item, idx) => {
				formatted.push(item?.description);
			});

		return formatted.join(", ");
	};

	const validInputs = () => {
		let result = true;

		if (!isValid(parse(birthDate, "dd-MM-yyyy", new Date()))) {
			result = false;
			toast.error("Invalid date. The exepcted format is DD-MM-YYYY.");
		}

		if (patient.gender === "") {
			result = false;
			setSexClass("invalid");
			toast.error("Please specify the gender.");
		}

		if (patient.body_unit_id === null) {
			result = false;
			setBodyUnitClass("invalid");
			toast.error("Please specify the weight unit.");
		}

		return result;
	};

	const validateBirthDate = (target) => {
		if (target.value === "") return;
		if (!isValid(parse(target.value, "dd-MM-yyyy", new Date())))
			target.classList.add("invalid");
		else target.classList.remove("invalid");
	};

	/* get the body weight units */
	useEffect(() => {
		(async () => {
			const data = await getUnits({
				token: authData.access_token,
				unitTypeId: 1, // weight
			});
			if (data === "ERR_BAD_REQUEST") {
				toast.error(t(data.response.response.data));
				console.log(data);
			} else {
				setWeightUnits(
					data.map((item) => {
						return {
							value: item.id,
							label: `${item.description} (${item.code})`,
						};
					}),
				);
			}
		})();
	}, [authData.access_token, t]);

	/* if a patient is being edited (patientData is set), get the patient's history */
	useEffect(() => {
		if (patientData)
			(async () => {
				const historyData = await getPatientHistory(
					authData.access_token,
					patientData.id,
				);
				if (historyData === "ERR_BAD_REQUEST") {
					toast.error(t(historyData.response.response.data));
				} else {
					setHistory(formatHistoryData(historyData));
				}
			})();
	}, [authData.access_token, patientData, t]);

	const handleClose = () => {
		setPatient(null);
		props.onClose();
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		if (validInputs()) {
			onSubmit({
				...patient,
				patient_history: history.split(","),
				birth_date: formatDate(birthDate, "dd-MM-yyyy", "yyyy-MM-dd"),
			});
			setPatient(null);
			setHistory("");
			setBirthDate("");
			props.onClose();
		}
	};

	return (
		<Modal
			title={patient ? t("Edit patient") : t("Create new patient")}
			isOpen={props.isOpen}
			onClose={handleClose}
			className="patient-modal"
		>
			<form onSubmit={handleSubmit}>
				<p className="message__note my-1">
					<i className="ri-book-open-line"></i>
					{patient
						? t(
								"You can edit a patient file as long as the file hasn't been used in a treatment plan.",
						  )
						: t(
								"You are going to add a patient. After submitting changes are possible until the patient has been used in a session.",
						  )}
				</p>
				<div className="flex flex-col row-gap-2 mt-3">
					<Input
						id="first-name"
						label={t("First name")}
						value={patient.first_name}
						autoFocus={true}
						required
						onChange={(e) =>
							setPatient({ ...patient, first_name: e.target.value })
						}
					/>
					<Input
						id="last-name"
						label={t("Last name")}
						value={patient.last_name}
						required
						onChange={(e) =>
							setPatient({ ...patient, last_name: e.target.value })
						}
					/>
					<Input
						id="birth-date"
						label={t("Date of birth")}
						value={birthDate}
						// pattern="[0-9]{2}-[0-9]{2}-(19|20)\d{2}"
						// maxLength="10"
						// minLength="10"
						placeholder={t("DD-MM-YYYY")}
						required
						onChange={(e) => setBirthDate(e.target.value)}
						onBlur={(e) => validateBirthDate(e.target)}
						onFocus={(e) => {
							e.target.classList.remove("invalid");
						}}
					/>
					<Select
						id="sex"
						className={sexClass}
						label={t("Sex")}
						value={selectSexOptions.find((obj) => obj.value === patient.gender)}
						options={selectSexOptions}
						allowNone={false}
						required
						onChange={(value) => {
							setPatient({ ...patient, gender: value.value });
						}}
						onFocus={() => {
							setSexClass("");
						}}
					/>
					<div className="flex gap-2">
						<InputNumber
							id="body-weight"
							className="flex-1"
							label={t("Body weight")}
							value={patient.body_weight || ""}
							onChange={(value) =>
								setPatient({ ...patient, body_weight: value })
							}
						/>
						<Select
							id="body-weight-unit-id"
							className={bodyUnitClass}
							label={t("Unit")}
							value={weightUnits.find(
								(obj) => obj.value === patient.body_unit_id,
							)}
							options={weightUnits}
							allowNone={false}
							onChange={(value) => {
								setPatient({ ...patient, body_unit_id: value.value });
							}}
							onFocus={() => {
								setBodyUnitClass("");
							}}
						/>
					</div>
					<InputNumber
						id="kidney-function"
						label={t("eGFR")}
						value={patient.kidney_function || ""}
						adornment={t("ml/min/1.73m²")}
						onChange={(value) =>
							setPatient({ ...patient, kidney_function: value })
						}
					/>
					<Input
						id="history"
						label={t("Medical history (comma separated)")}
						value={history}
						onChange={(e) => setHistory(e.target.value)}
					/>
					<div className="flex center mt-3">
						<Button className="btn-primary">
							{patient ? t("Save patient") : t("Create patient")}
						</Button>
					</div>
				</div>
			</form>
		</Modal>
	);
};

export default PatientModal;
